#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  background: #0093B2 !important;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  background: white !important;
}

#sidebar-wrapper .list-group {
  width: 15rem;
  background: #0093B2 !important;
}

#sidebar-wrapper .list-group-item {
  width: 15rem;
  background: #0093B2 !important;
  color: white;
}

#sidebar-wrapper .list-group-item.active {
  width: 15rem;
  /* background:black !important; */
  background:#005264 !important;
  color: white;
}

#sidebar-wrapper .list-group-item:hover {
  background:#005264 !important;
}

.inactive {
  color: white !important;
  text-decoration: none !important;
}

.active {
  color:white !important;
  background:white !important;
  /* font-weight: bolder !important; */
}

.logoutButton {
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  width: 15rem;
  background: #0093B2 !important;
  color: white;
  height: 10%;
  justify-content: center;
  align-content: center;
  padding-left: 1.8%;
  padding-top: 1.8%;
  border-top: white solid 2px;
}

.submenuText {
  margin-left: 50px !important;
}

.iconClass {
  margin-left: 0.7rem;
}

@media (min-width: 450px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}




