.bg-blue {
  background-color: #009aff !important;
}

.baseBtn {
  background-color: #009aff;
  color: white;
  border: none;
  box-shadow: 1px 1px 3px 0px #707070;
  height: 50px;
  font-weight: 600;
}

.smallBtn {
  color: white !important;
  border: none !important;
  box-shadow: 1px 1px 3px 0px #707070 !important;
  height: 50px !important;
}

input {
  background-color: #eeeeee !important;
  border-radius: unset !important;
  border: none !important;
  height: 50px !important;
}

select {
  background-color: #eeeeee !important;
  border-radius: unset !important;
  border: none !important;
  height: 50px !important;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputGroup {
  background-color: #eeeeee !important;
  border-radius: unset !important;
  border: none !important;
  height: 50px !important;
}

.textarea {
  background-color: #eeeeee !important;
  border-radius: unset !important;
  border: none !important;
}

select {
  background-color: #eeeeee !important;
  border-radius: unset !important;
  border: none !important;
  height: 50px !important;
}

.pointer {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: 0 0 0 0.09rem rgb(95 166 243) !important;
}

.centerPage {
  padding: 25px;
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 240px;
  overflow-x: hidden;
  overflow-y: auto;
}

#wrapper {
  overflow-x: hidden;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .centerPage {
    padding: 25px;
    position: initial;
    bottom: 0px;
    top: 0px;
    right: 0px;
    left: 240px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

ul {
  list-style: none;
  padding: 0;
}

li {
  padding-left: 1.3em;
}

li:before {
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

.pointer {
  cursor: pointer;
}

.colorText {
  color: #0093b2 !important;
}

.colorItem {
  background: #0093b2 !important;
}

.colorItemDarker {
  background: #005264 !important;
}

.colorItemLigther {
  background: #00b3d9 !important;
}

/* datepicker */
.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}
